// TODO: spôsobuje error na tv
import throttle from 'lodash/throttle'
import { setElHeight, convertImages } from './helpers.js'

document.addEventListener('DOMContentLoaded', function () {

    setTimeout( function() {
        console.log('Hello from scripts.js');
    }, 4000);

    const body = document.body
    const scrollToTop = document.getElementById('js-scroll-top')
    const urlObj = new URL(window.location.href)

    convertImages('img.to-svg')

    if (urlObj.searchParams.has('do')) {
        if (urlObj.searchParams.get('do') === 'registration') {
            showModal('RegisterModal')
        }
    }

    window.addEventListener('scroll', function () {
        const mainHeader = document.getElementById('js-header')
        const scrollPosition = window.pageYOffset

        if (!body.classList.contains('profile')) {
            if (scrollPosition > 0) {
                mainHeader.classList.add('scrolled')
            } else {
                mainHeader.classList.remove('scrolled')
            }
        }

        if (scrollToTop) {
            if (scrollPosition > 300) {
                scrollToTop.classList.add('active')
            } else {
                scrollToTop.classList.remove('active')
            }
        }
    })

    if (scrollToTop) {
        scrollToTop.addEventListener('click', function (e) {
            e.preventDefault()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        })
    }

    //
    // Profile
    //----------------------------------------------
    // const profileSidebar = document.getElementById('js-profile-sidebar')
    // if ( profileSidebar ) {
    //     // find a in the ul.root bot not in the ul.submenu
    //     const profileSidebarLinks = profileSidebar.querySelectorAll('ul.root > li > a:not(.submenu a)')
    //     profileSidebarLinks.forEach(link => {
    //         link.addEventListener('click', function (e) {
    //         })
    //     })
    // }
    //
    // Progress bars
    //----------------------------------------------
    // Hamburger
    //----------------------------------------------
    const hamburger = document.getElementById('js-hamburger')
    const mainSidebar = document.getElementById('js-main-sidebar')

    if (hamburger) {
        hamburger.addEventListener('click', function (e) {
            e.preventDefault()
            this.children[0].classList.toggle('active')
            mainSidebar.classList.toggle('active')
        })
    }

    //
    // Modals handling
    //----------------------------------------------
    const modalTriggers = document.querySelectorAll('.js-modal-trigger')

    if (modalTriggers) {
        modalTriggers.forEach(trigger => {
            trigger.addEventListener('click', function (e) {
                console.log(
                    'click on modal trigger',
                    this
                )
                e.preventDefault()

                let modalName = trigger.getAttribute('data-modal-name')
                let modalData = trigger.getAttribute('data-modal-props')
                if (modalName) showModal(modalName, modalData)

                // if (mainSidebar && mainSidebar.classList.contains('active')) {
                //     mainSidebar.classList.remove('active')
                //     hamburger.children[0].classList.remove('active')
                // }
            })
        })
    }

    //dynamically rendered triggers
    document.body.addEventListener('click', function (e) {
        if (e.target.classList.contains('js-modal-trigger')) {
            e.preventDefault();
            let modalName = e.target.getAttribute('data-modal-name');
            let modalData = e.target.getAttribute('data-modal-props');
            if (modalName) showModal(modalName, modalData);
            if (mainSidebar && mainSidebar.classList.contains('active')) {
                mainSidebar.classList.remove('active');
                hamburger.children[0].classList.remove('active');
            }
        }
    });

    const buttons = document.querySelectorAll('.filter-item');

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            const key = this.dataset.key;   // Extract data-key
            const value = this.dataset.value; // Extract data-value

            const url = new URL(window.location.href);
            let query = new URLSearchParams(url.search);

            // Remove specific value from query
            let values = query.get(key).split(',').filter(val => val !== value);

            if (values.length > 0) {
                query.set(key, values.join(','));
            } else {
                query.delete(key);
            }

            // Modify and reload url
            window.location.href = url.origin + url.pathname + '?' + query.toString();
        });
    });

    //
    // Posts section
    //----------------------------------------------
    const postsSection = document.getElementById('js-posts-section')

    if (postsSection) {
        setElHeight(postsSection.querySelectorAll('.c-post p'))
    }

    //
    // Google reCAPTCHA
    // --------------------------------------------------
    const webForm = document.querySelectorAll('.js-form-gr')

    if (webForm) {

        webForm.forEach(function (form) {
            let submitHandler = function (event) {
                event.preventDefault()

                const dataKey = document.getElementById('js-form-gr-key').getAttribute('data-key')

                grecaptcha.ready(function () {
                    grecaptcha.execute(dataKey, {action: 'send'}).then(function (token) {
                        let input = document.createElement('input')
                        input.type = 'hidden'
                        input.name = 'token'
                        input.value = token

                        form.prepend(input)
                        form.removeEventListener('submit', submitHandler)
                        form.submit()
                    })
                })
            }

            form.addEventListener('submit', submitHandler)
        })
    }

    // $('.js-form-gr').submit(function (event) {
    //     event.preventDefault()
    //
    //     let $form = $(this)
    //
    //     const datakey = $('#js-form-gr-key').attr('data-key')
    //
    //     grecaptcha.ready(function () {
    //         grecaptcha.execute(datakey, {action: 'send'}).then(function (token) {
    //             $form.prepend('<input type="hidden" name="token" value="' + token + '">')
    //             $form.unbind('submit').submit()
    //         })
    //     })
    // })

    const fileUploaders = document.querySelectorAll('.js-file-uploader')

    if (fileUploaders) {
        fileUploaders.forEach(fileUploader => {
            fileUploader.querySelector('.c-file-uploader__inner').addEventListener('click', function (e) {
                e.preventDefault()
                let fileInput = this.parentElement.querySelector('.file-input')
                fileInput.click()

                fileInput.addEventListener('change', function (e) {
                    let file = e.target.files[0];
                    if (!file.type.startsWith('image/')) {
                        flash('Nesprávny formát', 'error');
                        return;
                    }
                    flash('Obrázok bol zmenený', 'success')
                    fileUploader.querySelector('img.preview').setAttribute('src', URL.createObjectURL(e.target.files[0]))
                    fileUploader.querySelector('img.preview').classList.remove('hidden')
                    fileUploader.querySelector('div.upload-wrap').classList.add('hidden')
                    fileUploader.querySelector('.trash').classList.add('active')
                })
            })

            if (fileUploader.querySelector('img.preview')) {
                fileUploader.querySelector('.trash').classList.add('active')
                fileUploader.querySelector('.trash').addEventListener('click', function (e) {
                    e.preventDefault()
                    let fileInput = fileUploader.querySelector('.file-input')
                    fileInput.value = ''
                    fileInput.required = true
                    fileUploader.querySelector('img.preview').classList.add('hidden')
                    fileUploader.querySelector('div.upload-wrap').classList.remove('hidden')
                    this.classList.remove('active')
                })
            }
        })
    }
})
